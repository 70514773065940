import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum StepType {
  calculation = 'calculation',
  rule = 'rule',
  cycle = 'cycle',
}

export const stepTypes: SelectItem[] = [
  new SelectItem().setValue(StepType.calculation).setTitle('Calculation'),
  new SelectItem().setValue(StepType.rule).setTitle('Rule'),
  new SelectItem().setValue(StepType.cycle).setTitle('Cycle'),
]
