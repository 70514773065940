import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum StepFunction {
  sum = 'SUM(param1, param2)',
  min = 'MIN(param1, param2)',
  max = 'MAX(param1, param2)',
  avg = 'AVG(param1, param2)',
  lookUp = 'LOOKUP("table", "retrieve_column", "by_column", value)',
  lookRange = 'LOOKRANGE("table", "output_column", value, "from_column", "to_column")',
  if = 'IF(value, "<", value2, "true", "false")',
  ifEquals = 'IFEQUALS(value, value2, "true", "false")',
  ifEq = 'IFEQ(value, value2, "true", "false")',
  ifNotEquals = 'IFNOTEQUALS(value, value2, "true", "false")',
  ifNeq = 'IFNEQ(value, value2, "true", "false")',
  ifGreater = 'IFGREATER(value, value2, "true", "false")',
  ifGt = 'IFGT(value, value2, "true", "false")',
  ifGtEquals = 'IFGREATEREQUALS(value, value2, "true", "false")',
  ifGte = 'IFGTE(value, value2, "true", "false")',
  ifLess = 'IFLESS(value, value2, "true", "false")',
  ifLt = 'IFLT(value, value2, "true", "false")',
  ifLessEquals = 'IFLESSQUALS(value, value2, "true", "false")',
  ifLte = 'IFLTE(value, value2, "true", "false")',
  ifNa = 'IFNA(value, "alternative")',
  case = 'CASE(value, value1, "result1", value2, "result2")',
  inRange = 'INRANGE(value, from_value, to_value, value_in_range, value_out_range)',
  lower = 'LOWER(value)',
  upper = 'UPPER(value)',
  foreach = 'FOREACH("cycle_name", value, "cycle_item_key")',
  number = 'NUMBER(value)',
  decimal = 'DECIMAL(value)',
  string = 'STRING(value)',
  halt = 'HALT("rule_code")',
  sumArray = 'SUMARRAY(array, "key")',
  countArray = 'COUNTARRAY(array)',
  concat = 'CONCAT("delimiter", value, value2, value3)',
  arrayCombine = 'ARRCOMBINE(array_one, array_two)',
  arraySplit = 'ARRSPLIT(array, "type", "value")',
  ifExists = 'IFEXISTS(item, array, "true", "false")',
  summ = 'SUMM(array)',
  minm = 'MINM(array)',
  maxm = 'MAXM(array)',
  lookUpMany = 'LOOKUPM("table", "return_column", "column1", values_one, "column2", values_two)',
  in = 'IN(variable, value, value2, value3)',
  and = 'AND(variable, value, value2, value3)',
  or = 'OR(value, value2, value3)',
  haltIfRules = 'HALTIFRULES("decline")',
}

export const stepFunctions: SelectItem[] = [
  new SelectItem().setValue('sum').setTitle(StepFunction.sum),
  new SelectItem().setValue('min').setTitle(StepFunction.min),
  new SelectItem().setValue('max').setTitle(StepFunction.max),
  new SelectItem().setValue('avg').setTitle(StepFunction.avg),
  new SelectItem().setValue('lookup').setTitle(StepFunction.lookUp),
  new SelectItem().setValue('lookRange').setTitle(StepFunction.lookRange),
  new SelectItem().setValue('if').setTitle(StepFunction.if),
  new SelectItem().setValue('ifEquals').setTitle(StepFunction.ifEquals),
  new SelectItem().setValue('ifEq').setTitle(StepFunction.ifEq),
  new SelectItem().setValue('ifNotEquals').setTitle(StepFunction.ifNotEquals),
  new SelectItem().setValue('ifNeq').setTitle(StepFunction.ifNeq),
  new SelectItem().setValue('ifGreater').setTitle(StepFunction.ifGreater),
  new SelectItem().setValue('ifGt').setTitle(StepFunction.ifGt),
  new SelectItem().setValue('ifGtEquals').setTitle(StepFunction.ifGtEquals),
  new SelectItem().setValue('ifGte').setTitle(StepFunction.ifGte),
  new SelectItem().setValue('ifLess').setTitle(StepFunction.ifLess),
  new SelectItem().setValue('ifLt').setTitle(StepFunction.ifLt),
  new SelectItem().setValue('ifLessEquals').setTitle(StepFunction.ifLessEquals),
  new SelectItem().setValue('ifLte').setTitle(StepFunction.ifLte),
  new SelectItem().setValue('ifNa').setTitle(StepFunction.ifNa),
  new SelectItem().setValue('case').setTitle(StepFunction.case),
  new SelectItem().setValue('inRange').setTitle(StepFunction.inRange),
  new SelectItem().setValue('lower').setTitle(StepFunction.lower),
  new SelectItem().setValue('upper').setTitle(StepFunction.upper),
  new SelectItem().setValue('foreach').setTitle(StepFunction.foreach),
  new SelectItem().setValue('number').setTitle(StepFunction.number),
  new SelectItem().setValue('decimal').setTitle(StepFunction.decimal),
  new SelectItem().setValue('string').setTitle(StepFunction.string),
  new SelectItem().setValue('halt').setTitle(StepFunction.halt),
  new SelectItem().setValue('sumArray').setTitle(StepFunction.sumArray),
  new SelectItem().setValue('countArray').setTitle(StepFunction.countArray),
  new SelectItem().setValue('concat').setTitle(StepFunction.concat),
  new SelectItem().setValue('arrCombine').setTitle(StepFunction.arrayCombine),
  new SelectItem().setValue('arrSplit').setTitle(StepFunction.arraySplit),
  new SelectItem().setValue('ifExists').setTitle(StepFunction.ifExists),
  new SelectItem().setValue('sumM').setTitle(StepFunction.summ),
  new SelectItem().setValue('minM').setTitle(StepFunction.minm),
  new SelectItem().setValue('maxM').setTitle(StepFunction.maxm),
  new SelectItem().setValue('lookUpMany').setTitle(StepFunction.lookUpMany),
  new SelectItem().setValue('in').setTitle(StepFunction.in),
  new SelectItem().setValue('and').setTitle(StepFunction.and),
  new SelectItem().setValue('or').setTitle(StepFunction.or),
  new SelectItem().setValue('haltIfRules').setTitle(StepFunction.haltIfRules),
]
