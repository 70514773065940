

















































































































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import Calculator from '@/shared/modules/calculator/calculator'
import draggable from 'vuedraggable'
import http from '@/shared/http'
import ResponseInterface from '@/shared/interfaces/response.interface'
import Step from '@/shared/modules/step/step'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import Search from '@/components/data-table/Search.vue'
import Parameter from '@/shared/modules/parameter/parameter'
import _ from 'lodash'
import { StepType } from '@/shared/configs/step-types'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'
import capitalize from '@/shared/helpers/capitalize'
import Form from '@/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import HttpMethod from '@/shared/configs/http-method.config'
import SelectField from '@/shared/classes/form/fields/select/select-field'
import Field, { FieldSize } from '@/shared/classes/form/field'
import { FilterOperator } from '@/shared/classes/data-table/data-table-filter'
import { FieldType } from '@/shared/configs/field-type.config'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import { stepFunctions } from '@/shared/configs/step-equation.config'
import store from '@/shared/store'

@Component({
  components: { Form, Search, draggable },
  methods: { capitalize }
})
export default class DataTables extends Vue {
  @Inject('calculator') getCalculator!: () => Calculator

  StepType = StepType
  loading = true
  reorderLoading = false
  steps: Step[] = []
  parameters: Parameter[] = []
  openedPanel: string | null = null
  dragOptions = {
    animation: 200,
    ghostClass: 'ghost',
    handle: '.drag-icon'
  }
  errors: any = {}
  loadingSteps: any = {}
  oldValue: string | null = null
  stepsField: SelectField = new SelectField()
    .setKey('parent_uuid')
    .setTitle('Parent')
    .loadItems({
      endpoint: `clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps`,
      value: 'uuid',
      title: 'name',
      filters: [
        {
          type: FilterOperator.nulled,
          name: 'name',
          value: false,
        }
      ]
    })
  forms: FormBase[] = []
  stepFunctions: SelectItem[] = stepFunctions
  showEquationHint: any[] = []
  hintItems: SelectItem[] = []
  equationPosition: number | null = null

  mounted() {
    this.$watch('$route', this.loadSteps)
  }

  async created() {
    await this.loadParameters()
    await this.loadSteps()
  }

  async loadParameters() {
    await http.get(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/parameters?per_page=1000`)
      .then((response: ResponseInterface<Parameter>) => {
        this.parameters = response.data.data.map((parameter: Parameter) => parameter)
      })
  }

  async loadSteps() {
    const { q } = this.$router.currentRoute.query
    const searchParams = new URLSearchParams('')
    if (q) searchParams.set('q', q.toString())

    // searchParams.set('filter-nulled-parent_uuid', 'true')

    await http.get(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps/list?${ searchParams.toString() }`)
      .then((response: ResponseInterface<Step>) => {
        this.steps = response.data.map((step: Step) => step)
        this.loading = false
      })
  }

  focusOut(step: Step, currentValue: any) {
    if (this.oldValue === currentValue) return

    _.set(this.loadingSteps, step.uuid, true)
    this.loadingSteps = _.cloneDeep(this.loadingSteps)

    http.put(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps/${ step.uuid }/auto-save`, {
      parameter_uuid: step.parameter_uuid,
      equation: step.equation,
    })
      .catch((error: any) => {
        _.set(this.errors, step.uuid, error.response.data.errors)
        this.errors = _.cloneDeep(this.errors)
      })
      .finally(() => {
        _.set(this.loadingSteps, step.uuid, 'success')
        this.loadingSteps = _.cloneDeep(this.loadingSteps)

        setTimeout(() => {
          _.unset(this.loadingSteps, step.uuid)
          this.loadingSteps = _.cloneDeep(this.loadingSteps)
        }, 1200)
      })

    _.set(this.showEquationHint, step.uuid, false)
  }

  async onDragEnd(event: CustomEvent | any) {
    if (event.oldDraggableIndex === event.newDraggableIndex) return;
    if (event.oldDraggableIndex === this.openedPanel) this.openedPanel = event.newDraggableIndex
    this.steps = this.steps.map((item: Step) => item)
    await this.reorder()
  }

  openPanel(uuid: string) {
    this.openedPanel = uuid === this.openedPanel ? null : uuid
  }

  async deleteStep(step: Step) {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 400,
      component: AreYouSureDialog,
      meta: {
        onYes: () => {
          http.delete(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps/${ step.uuid }`)
            .then(this.loadSteps)
        }
      }
    })
  }

  async reorder() {
    this.reorderLoading = true

    await http.post(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps/reorder`, this.steps.map((step: Step) => step.uuid))
    await this.loadSteps()

    this.reorderLoading = false
  }

  createNewStep(type: StepType) {
    if (this.handleExceptions(type)) {
      return
    }

    const index: any = this.steps.length > 0 ? this.steps[this.steps.length - 1].index + 1 : 0
    const data = this.generateSampleStepData(type, index)

    this.createStep(data)

    this.openedPanel = index
  }

  generateSampleStepData(type: StepType, index: number) {
    return {
      index,
      type,
      equation: 'Replace formula here...',
      name: type === StepType.cycle ? 'example_cycle_name' :  null,
      parameter_uuid: type !== StepType.rule ? this.parameters[0].uuid : null,
      parent_uuid:  null,
      description: null,
      parameter: null,
      parent: null,
      children: []
    }
  }

  async createStep(data: any) {
    return await http.post(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps`, data)
      .then(this.loadSteps)
  }

  handleExceptions(type: StepType) {
    let exception = false

    switch (type) {
      case StepType.calculation:
        if (this.parameters.length > 0) {
          return exception
        }

        store.dispatch(GlobalActions.showSnackbar, {
          type: SnackBarType.error,
          message: 'Create at least 1 parameter!',
        })

        return true
      case StepType.cycle:
        if (this.parameters.length > 0) {
          return exception
        }

        store.dispatch(GlobalActions.showSnackbar, {
          type: SnackBarType.error,
          message: 'Create at least 1 parameter!',
        })

        return true
      case StepType.rule:
        return exception
    }
  }

  getDynamicForm(step: Step) {
    const createdForm: FormBase | null = _.get(this.forms, step.uuid)

    if (createdForm) {
      return _.cloneDeep(createdForm).setData(step)
    }

    const form: FormBase =  new FormBase()
      .setEntry(step)
      .setMethod(HttpMethod.PUT)
      .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/steps/${ step.uuid }/auto-save-detailed`)
      .setFields([
        new Field()
          .setKey('name')
          .setTitle('Name')
          .setSize(FieldSize.half)
          .setVisibleIf(() => {
            return step.type === StepType.cycle
          }),
        new SelectField()
          .setKey('parent_uuid')
          .setTitle('Parent')
          .setItems(this.stepsField.items)
          .setSize(FieldSize.half),
        new Field()
          .setType(FieldType.textArea)
          .setKey('description')
          .setTitle('Description'),
      ])
      .setOnSuccess(async () => {
        await this.loadSteps()
        this.openedPanel = null

        this.$store.dispatch(GlobalActions.showSnackbar, {
          type: SnackBarType.success,
          message: 'Step was successfully updated',
        })
      })
      .setInjectValues({
        type: step.type,
      })
      .setSubmit(false)

    _.set(this.forms, step.uuid, form)

    return form
  }

  async submitForm(step: Step) {
    const form: Form | any = _.get(this.$refs, `${ step.uuid }-form.0`)

    if (! form) {
      return
    }

    const errors: any = await form.submit()

    if (errors) {
      _.set(this.errors, step.uuid, errors.data)
      this.errors = _.cloneDeep(this.errors)
    }
  }

  getStepTypeTitle(step: Step) {
    return step.type ? capitalize(step.type) : ''
  }

  startTypingEquation(step: Step, event: KeyboardEvent) {
    this.removeError(`${ step.uuid }.equation`)

    if (! event) return

    this.steps.filter((item: Step) => item.uuid !== step.uuid).forEach((item: Step) => {
      _.set(this.showEquationHint, item.uuid, false)
    })

    const input: any = (_.get((this.$refs as any), `equation-${ step.uuid }`)[0] as any)
    const textArea: any = input.$refs.input

    const sentence = textArea.value
    let pos = textArea.selectionStart
    if (pos === undefined) {
      pos = 0
    }

    let char = String.fromCharCode(event.keyCode)

    if(/^[A-Za-z]+$/.test(char)) {
      const isLowerCaseLetter: boolean = (/[a-z]/.test(event.key));

      if (this.equationPosition === null) this.equationPosition = pos > 0 ? pos - 1 : 0

      let queryString = sentence.substr(this.equationPosition, pos - this.equationPosition)

      if (isLowerCaseLetter) this.hintItems = this.parameters
          .filter((parameter: Parameter) => parameter.name.toLowerCase().includes(queryString.toLowerCase()))
          .map((parameter: Parameter) => new SelectItem().setValue(parameter.name).setTitle(parameter.name))

      else this.hintItems = this.stepFunctions.filter((item: SelectItem) => item.title.toLowerCase().includes(queryString.toLowerCase()))

      _.set(this.showEquationHint, step.uuid, true)

      return
    } else if (event.key === 'Shift') {
      return
    } else if (event.key === 'Backspace') {
      _.set(this.showEquationHint, step.uuid, false)

      return
    }

    this.equationPosition = null

    _.set(this.showEquationHint, step.uuid, false)
  }

  pasteIntoEquation(step: Step, item: SelectItem) {
    const insertText: any = item.title
    if (! insertText.length) return

    const input: any = (_.get((this.$refs as any), `equation-${ step.uuid }`)[0] as any)
    const textArea: any = input.$refs.input

    const sentence = textArea.value
    const len = sentence.length
    let pos = textArea.selectionStart
    if (pos === undefined) {
      pos = 0
    }

    const before = sentence.substr(0, this.equationPosition)
    const after = sentence.substr(pos, len)

    step.equation = before + insertText + after

    input.onKeyDown()
    this.$nextTick().then(() => {
      textArea.selectionStart = pos + insertText.length
    })

    this.focusOut(step, step.equation)

    _.set(this.showEquationHint, step.uuid, false)

    this.equationPosition = null
  }

  removeError(path: string) {
    _.unset(this.errors, path)

    this.errors = _.cloneDeep(this.errors)
  }

  getError(path: string) {
    return !! _.get(this.errors, path)
  }

  isLoadingStep(uuid: string) {
    return !! _.get(this.loadingSteps, uuid)
  }

  isLoadingStepSuccess(uuid: string) {
    return _.get(this.loadingSteps, uuid) === 'success'
  }

  saveOldValue(value: any) {
    this.oldValue = _.cloneDeep(value)
  }

  getType(item: Step) {
    return item.type
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
